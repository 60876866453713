'use strict';

angular.module('enervexSalesappApp').factory('DesignImage', function ($resource) {
	return $resource('/api/accounts/:accountId/jobs/:jobId/designs/:designId/design-images/:id', {
		id: '@_id'
	},
	{
		save: {
			method: 'POST',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			}
		} ,
		update: {
			method: 'PUT',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			}
		},
	});
});
